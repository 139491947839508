<template>
    <div class="findpassword">
        <Top name="忘记密码" back></Top>
        <div class="password_w">
            <van-form ref="form" @submit="onSubmit">
                <van-field
                    v-model="form.phone"
                    name="pattern"
                    oninput = "value=value.replace(/[^\d]/g,'')"
                    label="手机号"
                    auto-complete="new-password"
                    placeholder="输入手机号码"
                />
                <van-field
                    v-model="form.yzm"
                    oninput = "value=value.replace(/[^\d]/g,'')"
                    name="validator"
                    center
                    label="验证码"
                    auto-complete="new-password"
                    placeholder="输入手机验证码"
                >
                    <template #button>
                        <GetYzmCode :username="form.phone"></GetYzmCode>
                    </template>
                </van-field>
                <van-field
                    v-model="form.oldPassword"
                    name="validator"
                    type="password"
                    label="新密码"
                    auto-complete="new-password"
                    placeholder="输入新密码"
                />
                <van-field
                    v-model="form.newPassword"
                    name="validator"
                    type="password"
                    label="再输一次"
                    auto-complete="new-password"
                    placeholder="再次输入新密码"
                />
                <div class="sumbit_btn">
                    <van-button round type="info" size="large" color="linear-gradient(to right, #FF704D, #F73727)" native-type="submit">确认并登录</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>
<script>
import Top from '@/components/top'
import GetYzmCode from '@/components/getYzmCode'

export default {
    name: 'Findpassword',
    components: {
        Top,
        GetYzmCode
    },
    data() {
        return {
            form: {
                phone: '',
                yzm: '',
                oldPassword: '',
                newPassword: '',
            },
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            disabled: false,
            codeText: '获取',
        }
    },
    methods: {
        //提交
        onSubmit() {
            if (this.form.phone != '' && this.form.yzm != '' && this.form.oldPassword != '' && this.form.newPassword != '') {
                if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.form.phone)) {
                    if (this.form.oldPassword == this.form.newPassword) {
                        //验证验证码
                        this.$api.register.checkCode({
                            username: this.form.phone,
                            code: this.form.yzm
                        }).then(res => {
                            if (res.code == 200) {
                                this.$api.login.resetPassword({
                                        username: this.form.phone,
                                        password: this.form.oldPassword,
                                        password2: this.form.newPassword,
                                        code: this.form.yzm
                                }).then(res => {
                                    if (res.code == 200) {
                                        localStorage.setItem('token', 'Bearer '+ res.data.token);
                                        localStorage.setItem('username', this.form.phone);
                                        this.$router.push('/');
                                    }
                                }).catch(err => {
                                    console.log(err);
                                });
                            }else{
                                this.$toast({
                                    message: '验证码错误！',
                                    duration: 1500
                                });
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                    }else{
                        this.$toast({
                            duration: 1500,
                            message: '两次密码不一致'
                        });
                    }
                }else{
                    this.$toast({
                        duration: 1500,
                        message: '请输入正确的手机号码'
                    });
                }
            }else{
                this.$toast({
                    duration: 1500,
                    message: '请先填完输入框'
                });
            }
        }
    }
}
</script>
<style>
.password_w{
    padding: 4% 3% 0;
}
.password_w .yzmcode_w button{
    top: -4px;
}
.password_w .van-cell{
    font-size: 1rem;
    padding: 18px 16px 10px;
}
.password_w .van-cell::after{
    border-bottom-color: #bbb;
}
.password_w .van-field__label{
    color: #333;
    width: 5em;
}
.password_w .sumbit_btn{
    margin: 30% 16px 2%;
}
.password_w .sumbit_btn button{
    font-size: 1.2rem;
}
</style>

